import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import GifImage from "../../assets/img/Edeekshaam_WebSite_Tree.drawio.svg";

const WebsiteSitemap = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="main-wrapper">
      <Helmet>
        <title>Sitemap - Edeekshaam</title>
        <meta
          name="description"
          content="Explore the Edeekshaam website for innovative educational resources, personalized learning experiences, and a vast collection of tools to help you achieve academic success."
        />
        <link rel="canonical" href="https://www.edeekshaam.in/sitemap" />
      </Helmet>

      <div className="row m-0 p-0 justify-content-center">
        <div className="responsive-svg-container">
          {isLoading ? (
            <div className="loading-spinner">
              <div
                className="spinner-border m-5 text-primary"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <object
                type="image/svg+xml"
                data={GifImage}
                className="gif-image"
                aria-label="Website Sitemap"
              ></object>
            </>
          )}
        </div>
      </div>

      <style>
        {`
          .responsive-svg-container {
            width: 100%;
            max-width: 2800px;
            height: auto;
            margin-top: 25px;
            margin-bottom: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .gif-image {
            width: 100%;
            height: auto;
          }
          .loading-spinner {
            font-size: 20px;
            color: #000;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 150px;
          }
        `}
      </style>
    </div>
  );
};

export default WebsiteSitemap;
